import api from "@/services/api";

export default {
  /**
   * Login
   *
   * @param username
   * @param password
   * @returns {*}
   */
  login(username, password, punchout) {
    return api().post("/api/auth/login", {
      username: username,
      password: password,
      punchout: punchout
    });
  },

  /**
   * Get current user
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  currentUser() {
    return api().get("/api/auth/user");
  },

  changePassword(old_password, new_password) {
    return api().post("/api/auth/password", {
      old_pw: old_password,
      new_pw: new_password
    });
  },

  createUser(username, password, name, email, shop_id, level) {
    return api().post("/api/auth/create-user", {
      username: username,
      password: password,
      name: name,
      email: email,
      shop_id: shop_id,
      level: level
    });
  },

  /**
   * Logout
   *
   * @param username
   * @param password
   * @returns {Promise<AxiosResponse<any>>}
   */
  logout(username, password) {
    return api().get("/api/auth/logout");
  },

  /**
   * Create new cart
   *
   * @param title
   * @param shop_id
   * @returns {Promise<AxiosResponse<any>>}
   */
  createCart(title, shop_id) {
    return api().post("/api/cart", {
      title: title,
      shop_id: shop_id
    });
  },

  /**
   *
   * @param cart_id
   * @param shop_id
   * @param product
   * @returns {Promise<AxiosResponse<any>>}
   */
  addLineToCart(cart_id, shop_id, product) {
    return api().post("/api/cart/line/" + cart_id, {
      product: product,
      shop_id: shop_id
    });
  },

  submitContactForm(form) {
    return api().post("/api/form", form);
  },

  setCartStatus(cart_id, shop_id, status) {
    return api().put("/api/cart/" + cart_id + "/status", {
      shop_id: shop_id,
      status: status
    });
  },

  updateCartInfo(cart_id, cart) {
    return api().put("/api/cart/" + cart_id + "/update", {
      cart: cart
    });
  },

  /**
   * Get available carts for user
   * @returns {Promise<AxiosResponse<any>>}
   */
  getCarts() {
    return api().get("/api/cart");
  },

  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getShops() {
    return api().get("/api/auth/shops");
  },

  getCustomers() {
    return api().get("/api/auth/customers");
  },

  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getProducts() {
    return api().get("/api/products");
  },

  /**
   *
   * @param shop
   * @param line
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSingleProduct(shop, line) {
    return api().get("/api/products/" + shop + "/" + line);
  }
};
