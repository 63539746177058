<template>
  <div>
    <div class="row">
      <div class="col about text-center pb-5">
        <h2>{{ $t("contactform.title") }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-4 offset-2 about">
        <input
          type="text"
          maxlength="30"
          :placeholder="$t('contactform.name')"
          v-model="formData.name"
          class="form-cart mb-4"
        />
        <input
          type="text"
          maxlength="30"
          :placeholder="$t('contactform.phone')"
          v-model="formData.phone"
          class="form-cart mb-4"
        />
        <input
          type="email"
          maxlength="30"
          :placeholder="$t('contactform.email')"
          v-model="formData.email"
          class="form-cart mb-4"
        />
      </div>
      <div class="col-4 about">
        <input
          type="text"
          maxlength="30"
          :placeholder="$t('contactform.companyName')"
          v-model="formData.companyName"
          class="form-cart mb-4"
        />
        <input
          type="text"
          maxlength="30"
          :placeholder="$t('contactform.orgNumber')"
          v-model="formData.orgNumber"
          class="form-cart mb-4"
        />
        <input
          type="text"
          maxlength="30"
          :placeholder="$t('contactform.postalCode')"
          v-model="formData.postalCode"
          class="form-cart mb-4"
        />
        <button @click="submitForm" class="btn btn-primary">
          {{ $t("contactform.submit") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputAPI from "@/services/api/input";

export default {
  data() {
    return {
      formData: {
        name: "",
        phone: "",
        email: "",
        companyName: "",
        orgNumber: "",
        postalCode: ""
      }
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await InputAPI.submitContactForm(this.formData);
        this.$toast.success("Ditt meddelande har skickats.", {
          timeout: 3000
        });
        this.resetForm();
      } catch (error) {
        this.$toast.error("Ett fel inträffade.", {
          timeout: 3000
        });
      }
    },
    resetForm() {
      this.formData = {
        name: "",
        phone: "",
        email: "",
        companyName: "",
        orgNumber: "",
        postalCode: ""
      };
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthComplete;
    }
  }
};
</script>
